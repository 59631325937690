// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import menuReducers from '../../views/backend/configurationUser/menu/menuStore/reducers'
import branchRegionReducers from '../../views/backend/configurationUser/region-branch-master/regionBranStore/reducers'
import locationReducers from '../../views/backend/configurationUser/locations/locationStore/reducers'
import mailReducers from '../../views/backend/configurationUser/mailSmsConfig/configStore/reducers'
import OrgReducers from '../../views/backend/configurationUser/organization/orgStore/reducers'
import makeModelReducers from '../../views/backend/admin/master-vehicle-setup/makeModelStore/reducers'
import loginReducers from '../../views/pages/LoginReducer'
import usersReducers from '../../views/backend/admin/manage-users/UsersReducers'
import LeadReducers from '../../views/backend/leads/leadStore/reducers'
import CarReducers from '../../views/ui/home/car/carStore/reducers'
import privillegeReducers from '../../views/backend/privilege/PrivillegeReducer'
import BikeReducers from '../../views/ui/home/bike/bikeStore/reducers'
import HomeReducers from '../../views/ui/home/homeStore/reducers'
import { RequestResponseReducers } from '../../views/backend/request-and-response/ReqReducers'
import posReducers from '../../views/backend/pos-request/posStore/reducer'
import DashboardReducers from '../../views/backend/dashboard/dashboardStore/reducers'
import b2cReducers from '../../views/backend/configurationUser/b2c-config/b2cStore/reducers'
import InsurerPrivReducer from '../../views/backend/configurationUser/insurer-privilege/InurerPrivilegeStore/reducers'
import SystemConfigReducers from '../../views/backend/configurationUser/system-config/systemConfigStore/reducers'
import JobSchedulerReducers from '../../views/backend/configurationUser/jobScheduler/JobSchedulerStore/reducers'
import LifeReducers from '../../views/backend/life-reports/lifeStore/reducers'
import HealthReducers from '../../views/ui/home/health/store/reducers'
import posMappingReducers from '../../views/backend/admin/pos-mapping/store/reducer'
import PaymentReducers from '../../views/backend/admin/paymentDetails/store/reducer'
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  menuReducers,
  branchRegionReducers,
  locationReducers,
  mailReducers,
  OrgReducers,
  loginReducers,
  usersReducers,
  makeModelReducers,
  CarReducers,
  privillegeReducers,
  BikeReducers,
  HomeReducers,
  RequestResponseReducers,
  posReducers,
  DashboardReducers,
  LeadReducers,
  b2cReducers,
  InsurerPrivReducer,
  SystemConfigReducers,
  JobSchedulerReducers,
  LifeReducers,
  HealthReducers,
  posMappingReducers,
  PaymentReducers
})

export default rootReducer
