
export const LOAD_ALL_PINCODE = "LOAD_ALL_PINCODE"
export const EMPTY_LOCAL_SORE = "EMPTY_LOCAL_SORE"
export const GET_HEALTH_QUOTES = "GET_HEALTH_QUOTES"
export const HEALTH_QUOTES_STORE = "HEALTH_QUOTES_STORE"
export const GET_ALL_RELATION = "GET_ALL_RELATION"
export const GET_ALL_MEMBER_OCCUPATION = "GET_ALL_MEMBER_OCCUPATION"
export const GET_ALL_STATE = "GET_ALL_STATE"
export const GET_ALL_CITY = "GET_ALL_CITY"
export const POLICY_DETAILS_ADDED = "POLICY_DETAILS_ADDED"
export const SELECTED_PRODUCT_ADDED = "SELECTED_PRODUCT_ADDED"
export const FAMILY_STORE = "FAMILY_STORE"
export const ADDONS_STORE = "ADDONS_STORE"
export const NIVABHUPA_QUOTES_VALUE = "NIVABHUPA_QUOTES_VALUE"
export const EMPTY_NIVABHUPA_DATA = "EMPTY_NIVABHUPA_DATA"
export const NIVABHUPA_ADDONS = "NIVABHUPA_ADDONS"

export const PROPOSER_DETAILS = "PROPOSER_DETAILS"
export const INSURED_MEMBER = "INSURED_MEMBER"
export const COMMUNICATION_ADDRES = "COMMUNICATION_ADDRES"
export const MEDICAL_HISTORY = "MEDICAL_HISTORY"
export const GET_ALL_MEDICAL = "GET_ALL_MEDICAL"

export const GET_HEALTH_PROPOSAL = "GET_HEALTH_PROPOSAL"
export const SBI_KYC_RES = "SBI_KYC_RES"
export const COVERED_BENEFITS = "COVERED_BENEFITS"

export const GET_NOMINEE_RELATIONS_INSURERID = "GET_NOMINEE_RELATIONS_INSURERID"

export const GET_ALL_OCCUPATIONS = "GET_ALL_OCCUPATIONS"
export const GET_ALL_GENRIC_OCCUPATIONS = "GET_ALL_GENRIC_OCCUPATIONS"
export const GET_ALL_MEMBER_EDUCATION = "GET_ALL_MEMBER_EDUCATION"

export const CARE_KYC_RES = "CARE_KYC_RES"
export const HDFC_KYC_RES = "HDFC_KYC_RES"

export const PROPOSAL_ALL_DATA_EMPTY = "PROPOSAL_ALL_DATA_EMPTY"

export const PROPOSAL_LOCAL_SORE = "PROPOSAL_LOCAL_SORE"
export const GENERIC_KYC_RESP = "GENERIC_KYC_RESP"

export const NIVABHUPA_KYC_RESPONSE_STORE = "NIVABHUPA_KYC_RESPONSE_STORE"
export const STATE_CHANGE_EMPTY_CITY = "STATE_CHANGE_EMPTY_CITY"