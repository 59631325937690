export const GET_ALL_BIKE_MAKES = "GET_ALL_BIKE_MAKES"
export const GET_ALL_BIKE_MODEL = "GET_ALL_BIKE_MODEL"
export const GET_FUEL = "GET_FUEL"
export const GET_ALL_BIKE_VARIANT = "GET_ALL_BIKE_VARIANT"
export const GET_ALL_BIKE_RTO = "GET_ALL_BIKE_RTO"

export const ADD_BIKE_MAKE = "ADD_BIKE_MAKE"
export const ADD_BIKE_MODEL = "ADD_BIKE_MODEL"
export const ADD_BIKE_FUEL = "ADD_BIKE_FUEL"
export const ADD_BIKE_VARIANT = "ADD_BIKE_VARIANT"
export const ADD_BIKE_RTO = "ADD_BIKE_RTO"
export const ADD_BIKE_YEAR = "ADD_BIKE_YEAR"

export const GET_ALL_STATE = "GET_ALL_STATE"
export const GET_ALL_CITY = "GET_ALL_CITY"

export const GET_ALL_RELATIONSHIP = "GET_ALL_RELATIONSHIP"
export const GET_ALL_OCCUPATIONS = "GET_ALL_OCCUPATIONS"
export const GET_ALL_FINANCER = "GET_ALL_FINANCER"
export const GET_PREVIOUS_INSURER = "GET_PREVIOUS_INSURER"

export const GET_ALL_INSURER = "GET_ALL_INSURER"
export const GET_BIKE_QUOTES = "GET_BIKE_QUOTES"
export const GET_PROPOSAL = "GET_PROPOSAL"

export const EMPTY_JOURNEY = "EMPTY_JOURNEY"
export const QUOTES_REQUESTED_DATA = "QUOTES_REQUESTED_DATA"
export const SELECTED_BIKE_DATA = "SELECTED_BIKE_DATA"

export const EMPTY_QUOTES = "EMPTY_QUOTES"
export const ADD_RIDER = "ADD_RIDER"

export const PERSONAL_DETAILS = "PERSONAL_DETAILS"
export const OWNWE_DETAIL = "OWNWE_DETAIL"
export const ADDRESS_DETAIL = "ADDRESS_DETAIL"
export const VEHICLE_INFO_BIKE = "VEHICLE_INFO_BIKE"

export const GET_GODIGIT_KYC_CONTROLLER = "GET_GODIGIT_KYC_CONTROLLER"
export const GET_KYC_DETAIL = "GET_KYC_DETAIL"

export const RESET_PROPOSAL_RES = "RESET_PROPOSAL_RES"
export const SELECTED_INSURARER_DETAILS = "SELECTED_INSURARER_DETAILS"
export const DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD"
